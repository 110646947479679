
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.80.21 (e9d788f9b3a77da6b8f029eb31f4c17b)",
    buildSeed: 1703230148597,
    "wdosbox-x.wasm": {
        "size": 6527749,
        "gzSize": 0
    },
    "wdosbox-x.js": {
        "size": 252143,
        "gzSize": 0
    },
    "wdosbox.wasm": {
        "size": 1465302,
        "gzSize": 0
    },
    "wdosbox.js": {
        "size": 128379,
        "gzSize": 0
    },
    "wlibzip.wasm": {
        "size": 111405,
        "gzSize": 0
    },
    "wlibzip.js": {
        "size": 75391,
        "gzSize": 0
    }
,
};
